import React from "react"
import { Helmet } from "react-helmet"

const StructuredDataReels = ({ richsnippets = {} }) => {
  const ingredientearray=[];
  if(!richsnippets.preparacion){richsnippets.preparacion=5;}
  if(!richsnippets.tiempococina){richsnippets.tiempococina=5;}
  if(!richsnippets.tipococina){richsnippets.tipococina="Española";} else {richsnippets.tipococina = richsnippets.tipococina.Etiqueta;}
  if(!richsnippets.tipoplato){richsnippets.tipoplato="Almuerzo";} else {richsnippets.tipoplato = richsnippets.tipoplato.Etiqueta;}

  richsnippets.ingredientesreceta.map(ingrediente => ingredientearray.push(ingrediente.Cantidad===null?ingrediente.Ingrediente:(ingrediente.Cantidad + ' ') + ingrediente.Ingrediente))


  return (
    <Helmet>
      {(richsnippets.valido ? true : null) && (
        <script type="application/ld+json">
          {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Recipe",
              "author": "Paola Procell",
              "cookTime": "PT" + richsnippets.tiempococina + "M",
              "datePublished": richsnippets.fechapublicado,
              "description": richsnippets.descripcion,
              "image": (process.env.GATSBY_ROOT_URL || "http://localhost:8000/") +
              richsnippets.imagen.localFile.publicURL,
              "recipeIngredient": ingredientearray,
              "name": richsnippets.titulo,
              "prepTime": "PT" + richsnippets.preparacion + "M",
              "recipeInstructions": richsnippets.descripcion,
              "recipeYield": richsnippets.raciones,
              "suitableForDiet": "https://schema.org/LowFatDiet",
              "keywords":richsnippets.titulo,
              "recipeCuisine": richsnippets.tipococina,
              "recipeCategory": richsnippets.tipoplato,
              "video": {
                "@type": "VideoObject",
                "name": richsnippets.titulo,
                "description": richsnippets.descripcion,
                "thumbnailUrl": [(process.env.GATSBY_ROOT_URL || "http://localhost:8000/") +
                    richsnippets.poster.localFile.publicURL
                 ],
                "contentUrl": richsnippets.video[0].url,
                "uploadDate": richsnippets.fechapublicado
              }
          })}
        </script>
      )}
    </Helmet>
  )
}
export default StructuredDataReels