import React, {useEffect } from "react";
import { graphql,video } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Moment from "react-moment";
import parse from 'html-react-parser'
import Layout from "../../components/layout";
import GoogleAds from "../../components/googleads";
import StructuredDataReels from "../../components/structuredDataReels";
import Newsletter from "../../components/newsletter";
import Gridreelsdetail from "../../components/reelsgrid";
import ReactPlayer from 'react-player/lazy'

export const query = graphql`
  query ReelsQuery($slug: String!) {
                        strapiReels (
                            slug: { eq: $slug }
                            ) {
                            descripcionlarga
                            IngredientesReels{
                              Ingrediente
                              Cantidad
                            }
                            id
                            published_at
                            slug
                            titulo
                            meta
                            badge
                            URL_Instagram
                            tiemporeceta
                            SEO {
                              id
                              metaDescription
                              metaTitle
                              shareImage {
                                localFile {
                                  publicURL
                                }
                              }
                            }
                            poster {
                              localFile {
                                publicURL
                              }
                            }
                            video {
                              url
                            }
                          }
                        }
`;

const Reel = ({ data }) => {

  const reel = data.strapiReels;  

  const richsnippets = {
    valido:true,
    titulo: reel.titulo,
    preparacion: reel.tiemporeceta,
    tiempococina: reel.tiemporeceta,
    fechapublicado: reel.published_at,
    descripcion: reel.descripcionlarga,
    descripcionsoja: reel.descripcionlarga,
    imagen: reel.SEO.shareImage,
    ingredientesreceta: reel.IngredientesReels,
    raciones: reel.Rinde,
    instrucciones: reel.Instrucciones_Receta,
    poster: reel.poster,
    video:reel.video
  }
   // console.log(richsnippets.tipoplato);*/
  const seo = {
    metaTitle: reel.SEO.metaTitle,
    metaDescription: reel.SEO.metaDescription,
    shareImage: reel.SEO.shareImage,
    reel: true,
    url: "/reels/" + reel.slug
};


  // const imagenabajo = data.strapiArticle.Imagen_abajo.url;
  const poster = reel.poster.localFile.publicURL;
  // console.log(poster);

  return (
    <Layout seo={seo}>
      <StructuredDataReels richsnippets={richsnippets} />
      <div className="relative bg-white">
        <div className="max-w-2xl mx-auto py-6 px-4 sm:py-4 sm:px-6 lg:max-w-6xl lg:px-8 lg:grid lg:grid-cols-2">
        <div className="">
          <div className="lg:w-xl lg:mx-auto">
            {/* <video controls>
              <source src={reel.video[0].url} type="video/mp4" />
            </video> */}
            <div className="aspect-w-9 aspect-h-16 player-wrapper">
            <ReactPlayer
              className='react-player'
              url={reel.video[0].url}
              width='100%'
              height='100%'
              controls={true}
              loop={true}
              playing
              playsinline
              playIcon={<img src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/iconoplay_a40a72c71a.png" className="w-28"/>}
              light={poster}
            />
            </div>
            {reel.badge &&
              <div className="absolute top-4 text-sm px-3 bg-white text-black rounded">{reel.badge}</div>
            }
            <div className="mx-auto text-center">
              <a
                href={reel.URL_Instagram} target="_blank"
                className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-bold text-white bg-morado hover:bg-moradoclaro mt-8">
                VER EN INSTAGRAM
              </a>
            </div>
          </div>
        </div>
        <div className="lg:col-start-2 lg:pl-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0 poppins">
            <h2 className="leading-6 text-morado font-semibold tracking-wide poppins uppercase mt-4 mb-0">{reel.meta}</h2>
            <h1 className="mt-0 pb-4 text-3xl leading-8 font-medium text-gray-900 sm:text-4xl">
              {reel.titulo}
            </h1>
            <div className="reels" dangerouslySetInnerHTML={{__html: reel.descripcionlarga}}></div>
          </div>
        </div>
       
      </div>
    </div>
    <div className="max-w-2xl mx-auto py-6 lg:max-w-6xl">
    <GoogleAds/>
    </div>
   
    <div className="mt-10">
        <Gridreelsdetail />
      </div>
      <Newsletter />            
    </Layout>
  );
};
export default Reel;
